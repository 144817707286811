import axios from 'axios'
export function initWx() {

    axios.get('https://yb.tazw.net/dev-api/signature?url='+
    window.location.href).then((res) => {
//    axios.get('/dev-api/signature').then((res) => {
        console.log(res.data);
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wx6ecf81ec0c0c5cb1", // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
                "startRecord",
                "stopRecord",
                "onVoiceRecordEnd",
                "uploadVoice",
                "translateVoice",
            ], // 必填，需要使用的JS接口列表
        });
        wx.ready(() => console.log('微信配置成功'))
        wx.error((err) => alert(JSON.stringify(err)))
    });
}