<template>
  <div class="container">
    <div class="title">在线客服</div>
    <div class="bot-cont" v-show="show">
      <div class="box-text-cont">
        <div class="box-text-cont-row" style="line-height: 53px">
          HI，我是医保智能机器人，
        </div>
        <div class="box-text-cont-row" style="line-height: 27px">
          请问有什么可以帮助您的吗
        </div>
      </div>
      <div class="bot"></div>
    </div>
    <transition name="fade">
      <div v-show="show" class="search-bot">
        <input class="search-input" type="text" placeholder="搜索你遇到的问题" @click="handleClick" />
      </div>
    </transition>
    <transition name="fade">
      <div v-show="show" class="bottom-cont">
        <div class="icon-cont">
          <div class="icon-box" @click="change(0)">
            <img src="@/assets/static/icon1.png" alt="" />
            <div class="icon-text">异地就医</div>
          </div>
          <div class="icon-box" @click="change(1)">
            <img src="@/assets/static/icon2.png" alt="" />
            <div class="icon-text">医保结算</div>
          </div>
          <div class="icon-box" @click="change(2)">
            <img src="@/assets/static/icon3.png" alt="" />
            <div class="icon-text">参保登记</div>
          </div>
          <div class="icon-box" @click="change(3)">
            <img src="@/assets/static/icon4.png" alt="" />
            <div class="icon-text">医保报销</div>
          </div>
        </div>

        <!-- <div class="tab-bar">
          <div class="tab-bar-item active">
            <div class="top">猜你想问</div>
            <div class="bottom"></div>
          </div>
          <div class="tab-bar-item">
            <div class="top">服务条款</div>
            <div class="bottom"></div>
          </div>
          <div class="tab-bar-item">
            <div class="top">退换货品</div>
            <div class="bottom"></div>
          </div>
          <div class="tab-bar-item">
            <div class="top">分期支付</div>
            <div class="bottom"></div>
          </div>
        </div> -->

        <div class="questions">
          <div class="row" v-for="item in showData" @click="toInput(item)">
            <div class="text">{{ item }}</div>
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </transition>

    <div v-show="!show" class="chat-box">
      <div v-for="(item, index) in chatItems" :class="item.type + '-item'" :key="index">
        <template v-if="item.type == 'bot'">
          <div>{{ item.msg }}</div>
        </template>
        <template v-if="item.type == 'bot-select'">
          <div>
            <div style="height: 32px; font-size: 19px; line-height: 30px">
              猜您想问
            </div>
            <div v-for="(question, index) in item.msg" :key="index" style="
                line-height: 25px;
                margin: 10px 0;
                color: rgb(23, 147, 225);
              " @click="getQuestion(question)">
              {{ question._source.q }}
            </div>
          </div>
        </template>
        <template v-if="item.type == 'user'">
          <div>{{ item.msg }}</div>
        </template>
        <template v-if="item.type == 'hidden'"> </template>
      </div>
    </div>
    <div v-show="!show" class="input-box">
      <div v-show="!record" style="
          position: fixed;
          bottom: 20px;
          left: 16px;
          height: 39px;
          line-height: 40px;
          width: 38px;
          background: #ffffff7a;
          border-radius: 40px;
        " @click="handleStart">
        <img style="
            width: 90%;
            margin: 0 auto;
            display: block;
            transform: translateY(-50%);
            top: 50%;
            position: relative;
          " src="@/assets/static/mic.png" />
      </div>
      <input v-show="!record" class="search-input2" id="si" type="text" placeholder="请输入问题开始对话" v-model="inputText"
        @keyup.enter="handleEnter" @blur="" />

      <div style="
          height: 40px;
          line-height: 40px;
          background: rgba(255, 255, 255, 0.4392156863);
          width: calc(100vw - 60px);
          text-align: center;
          margin: 0 auto;
          border-radius: 20px;
        " @click="handleFinish" v-show="record">
        录音中，点击结束
      </div>
      <div v-show="!record" style="
          position: fixed;
          bottom: 20px;
          right: 35px;
          height: 40px;
          line-height: 40px;
          color: #03a9f4;
        " @click="handleEnter">
        发送
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Record from "../utils/record";
import { initWx } from "@/utils/initWx";
import getContent from "../utils/getContent";
import { getCurrentInstance } from "vue";
import blobToBase64 from "../utils/blob2b64";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      ws: null,
      cha: 0,
      show: true,
      record: false,
      inputText: "",
      list: [
        [
          "什么是异地就医联网结算",
          "哪些异地就医费用可以联网结算",
          "异地就医人员如何分类",
          "如何办理异地就医备案",
          "异地就医备案后如何变更",
          "省内跨市临时外出就医人员是否已取消备案",
          "若办理了 “异地长期居住人员”备案，又需要返回参保地就医的如何办理",
          " 异地就医自助备案方式的特点和优势有哪些",
          "参保人异地就医在哪些医院可以联网结算",
          "异地就医联网结算需要办理什么手续",
          "异地就医联网结算享受哪些待遇",
          "异地就医人员应注意哪些事项",
          "无法联网结算的异地就医费用应如何报销",
        ],
        [
          "哪些就医费用可以申请零星报销",
          "哪些人的医疗费用可以纳入零星报销",
          "零星报销办理流程有哪些",
          "意外伤害情形如何办理零星报销",
          "住院期间外院检查治疗或双通道购药如何办理零星报销…",
          "医保票据丢失如何办理报销",
        ],
        [
          "新参保人员如何办理城乡居民基本医疗保险参保登记",
          "续保人员如何办理城乡居民基本医疗保险参保登记",
          "城乡居民基本医疗保险参保登记每年的集中缴费期是什么时间",
          "居民参保待遇政策有哪些",
          "新生儿参保待遇政策有哪些",
        ],
        [
          "参保人在本地定点医疗机构就医，如何办理医保报销 ",
          " 什么是 “起付线”“封顶线”",
          "医保药品目录中的 “甲”“乙”类药品，有什么区别",
          "医保报销对住院天数是否有限制",
          "参保职工住院基本医疗保险和大额救助待遇是如何规定的",
          "职工大病保险待遇是如何规定的",
          "参保居民住院基本医疗保险待遇是如何规定的",
          "居民大病医疗保险待遇是如何规定的",
        ],
      ],
      showData: [],
      rec: {},
      chatItems: [
        {
          type: "bot",
          msg: "你好，欢迎使用智能机器人",
        },
      ],
    };
  },
  mounted() {
    // let r = getCurrentInstance()
    // this.chatItems.push({
    //   type: "bot",
    //   msg: JSON.stringify(r),
    // });
  },
  created() {
    let originalHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    window.onresize = function () {
      // alert('高度发现变化')
      //键盘弹起与隐藏都会引起窗口的高度发生变化
      var resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const activeEl = document.activeElement; // 获取当前点击的元素
      if (resizeHeight < originalHeight) {
        this.cha = originalHeight - resizeHeight;
        document.getElementsByClassName("chat-box")[0].scrollTop =
          document.getElementsByClassName("chat-box")[0].scrollHeight + cha;
      } else {
        //当软键盘收起，在此处操作
        this.cha = 0;
        let cha = resizeHeight - originalHeight;
        document.getElementsByClassName("chat-box")[0].scrollTop =
          document.getElementsByClassName("chat-box")[0].scrollHeight + cha;
      }
    };

    initWx();
    this.showData = this.list[0];
    this.rec = new Record();
  },
  methods: {
    handleStart() {
      this.record = true;
      // wx.startRecord();
      this.rec.mediaRecorder.start();
    },
    change(i) {
      this.showData = this.list[i];
    },
    async handleFinish() {
      let _this = this;
      this.record = false;
      // wx.stopRecord({
      //   success: function (res) {
      //     let localId = res.localId;
      //     wx.translateVoice({
      //       localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得
      //       isShowProgressTips: 1, // 默认为1，显示进度提示
      //       success: function (res) {
      //         // 语音识别的结果

      //         // alert(res.translateResult);
      //         _this.inputText = res.translateResult
      //         _this.handleEnter()

      //       },
      //       error: (res) => {
      //         alert(res)
      //       }
      //     });
      //   },
      // });
      console.log(this.rec);
      let audio = await this.rec.stop();
      let audiob64 = await blobToBase64(audio);
      console.log(audiob64);
    },
    handleFocusOut() { },
    getQuestion(question) {
      // axios.get('http://81.70.253.160:8080/getQuestion?id=' + question.id).then((res => {
      //   if (res.data.code == 200) {
      //     this.chatItems.push({
      //       type: "user",
      //       msg: question.question,
      //     });
      //     console.log(res)
      //     this.chatItems.push({
      //       type: "bot",
      //       msg: getContent(res.data.data.data.answer),
      //     });

      //     this.$nextTick(() => {
      //       document.getElementsByClassName("chat-box")[0].scrollTop = 100000
      //     })

      //   }
      // }))
      this.chatItems.push({
        type: "user",
        msg: question._source.q,
      });
      this.chatItems.push({
        type: "bot",
        msg: question._source.a,
      });

      this.$nextTick(() => {
        document.getElementsByClassName("chat-box")[0].scrollTop = 100000;
      });
    },
    toInput(item) {
      this.show = false;
      let _this = this;
      setTimeout(() => {
        document.getElementById("si").focus();
        _this.inputText = item;
        _this.handleEnter();
      }, 500);
    },
    handleClick() {
      this.show = false;
      setTimeout(() => {
        document.getElementById("si").focus();
      }, 500);
    },
    handleEnter() {
      console.log(this.inputText);
      if (this.ws) {
        this.ws.send(this.inputText);

        this.chatItems.push({
          type: "user",
          msg: this.inputText,
        });
        this.inputText = "";
        this.$nextTick(() => {
          document.getElementsByClassName("chat-box")[0].scrollTop = 100000;
        });
        retrun;
      }

      if (this.inputText.split("你好").length > 1) {
        this.chatItems.push({
          type: "user",
          msg: this.inputText,
        });
        this.chatItems.push({
          type: "bot",
          msg: "你好，有什么可以帮助您的吗",
        });
      } else if (this.inputText.split("余额").length > 1) {
        this.chatItems.push({
          type: "user",
          msg: this.inputText,
        });
        // alert(this.$route.query.token);
        axios
          .get("/dev-api/Ye?token=" + this.$route.query.token, {})
          .then((res) => {
            // alert(JSON.stringify(res.data));
            this.chatItems.push({
              type: "bot",
              msg: "您的余额为：" + JSON.stringify(res.data.ye),
            });
          })
          .catch((e) => { });
      } else {
        this.chatItems.push({
          type: "user",
          msg: this.inputText,
        });
        this.chatItems.push({
          type: "bot",
          msg: "智能机器人正在查找答案，请稍候15秒",
        });
        let i = this.chatItems.length - 1;
        let seconds = 15;
        let timer = setInterval(() => {
          if (seconds > 0) {
            seconds--;
            this.chatItems[
              i
            ].msg = `智能机器人正在查找答案，请稍候${seconds}秒`;
          } else {
            this.chatItems[
              i
            ].msg = `未找到答案`;
            clearInterval(timer);
          }
        }, 1000);

        axios.get('/dev-api/getDocs?question=' + this.inputText).then(res => {
          if (res.data.code == 200) {
            this.chatItems[i].type = "hidden";
            this.chatItems.push({
              type: "bot-select",
              msg: res.data.data,
            });
            let timer = setTimeout(() => {
              this.chatItems.push({
                type: "bot",
                msg: "请问帮您解决问题了吗？如果没有，请继续提问",
              });
              this.$nextTick(() => {
                document.getElementsByClassName(
                  "chat-box"
                )[0].scrollTop = 100000;
              });
              clearTimeout(timer);
            }, 5000);




          }
        })


        // const esUrl = "/es/qa/_search";
        // const queryBody = {
        //   query: {
        //     multi_match: {
        //       query: this.inputText,
        //       fields: ["*"],
        //     },
        //   },
        // };

        // axios
        //   .post("/es/qlog/_doc", {
        //     q: this.inputText,
        //     time: new Date(),
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
        // 使用axios发起POST请求
        //   axios.post(esUrl, queryBody).then((r) => {
        //     console.log("查询结果:", r.data);
        //     if (r.data.hits && r.data.hits.hits.length > 0) {
        //       this.chatItems[i].type = "hidden";
        //       this.chatItems.push({
        //         type: "bot-select",
        //         msg: r.data.hits.hits,
        //       });
        //       let timer = setTimeout(() => {
        //         this.chatItems.push({
        //           type: "bot",
        //           msg: "请问帮您解决问题了吗？如果没有，请继续提问",
        //         });
        //         this.$nextTick(() => {
        //           document.getElementsByClassName(
        //             "chat-box"
        //           )[0].scrollTop = 100000;
        //         });
        //         clearTimeout(timer);
        //       }, 5000);
        //     } else {
        //       const esUrl = "/es/qa/_search";
        //       const queryBody = {
        //         query: {
        //           multi_match: {
        //             query: "医保",
        //             fields: ["*"],
        //           },
        //         },
        //       };

        //       axios.post(esUrl, queryBody).then((r) => {
        //         console.log("查询结果:", r.data);
        //         if (r.data.hits && r.data.hits.hits.length > 0) {
        //           this.chatItems[i].type = "hidden";
        //           this.chatItems.push({
        //             type: "bot-select",
        //             msg: r.data.hits.hits,
        //           });
        //         }
        //       });
        //     }
        //   });
      }
      this.inputText = "";
      this.$nextTick(() => {
        document.getElementsByClassName("chat-box")[0].scrollTop = 100000;
      });

      //document.getElementsByClassName("chat-box")[0].scrollHeight + this.cha;
    },
    handleSwitchRengong() {
      let _this = this;
      _this.chatItems.push({
        type: "bot",
        msg: "人工服务正在接通",
      });
      axios.get("/ws-api/init").then((res) => {
        console.log(res.data.code);
        if (res.data.code == 749) {
          var ws = new WebSocket("wss://yb.tazw.net:8088");
          this.ws = ws;
          ws.onopen = function (event) {
            ws.send("ok");
          };
          ws.onmessage = function (event) {
            console.log(event.data);
            if (event.data.length > 0 && event.data[0] == "a") {
              let str = event.data;
              let msg = JSON.parse(str.slice(1, str.length));
              console.log(msg);
              msg = JSON.parse(msg[0]);
              msg = msg.msg;
              console.log(msg);
              _this.chatItems.push({
                type: "bot",
                msg,
              });
            }
          };
        } else {
          _this.chatItems.push({
            type: "bot",
            msg: "人工繁忙，请等待1人",
          });
        }
      });
    },
  },
};
</script>



<style lang="scss" scoped>
.fade-leave-active {
  transition: all 0.8s ease;
}

.fade-leave-to {
  transform: translatey(100px);
  opacity: 0;
}

.container {
  min-height: 100vh;
  width: 100vw;
}

.title {
  font-size: 20px;
  text-align: center;
  height: 40px;
  line-height: 55px;
  font-weight: 400;
  font-family: "Ping Fang SC";
}

.bot-cont {
  height: 170px;
  position: relative;
}

.box-text-cont {
  height: 80px;
  width: calc(100vw - 40px);
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.56);
  box-sizing: border-box;
  padding-left: 20px;
  border-radius: 10px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box-text-cont-row {
  color: black;
  height: 40px;
  font-size: 17px;
  font-weight: 500;
  font-family: "Source Han Sans CN";
}

.bot {
  width: 115px;
  height: 100%;
  background: url("~@/assets/static/bot.png") 0 0 / 100% 100% no-repeat;
  position: absolute;
  right: 20px;
}

.search-bot {
  height: 80px;
  box-sizing: border-box;
  padding-top: 40px;
}

.search-input {
  background: #ffffff70;
  border: none;
  height: 40px;
  width: calc(100vw - 40px);
  margin: 0 auto;
  display: block;

  border-radius: 20px;
  font-size: 17px;
  padding: 0 30px;
  box-sizing: border-box;
}

.search-input2 {
  background: rgba(255, 255, 255, 0.4392156863);
  border: none;
  height: 40px;
  width: calc(100vw - 80px);
  /* margin: 0 auto; */
  display: block;
  border-radius: 20px;
  font-size: 17px;
  padding: 0 30px;
  box-sizing: border-box;
  margin-left: 60px;
}

.bottom-cont {
  width: 100%;
  min-height: calc(100vh - 290px);
  border-radius: 10px 0 0 0;
  background: #ffffffcc;
  margin-top: 26px;
}

.icon-cont {
  box-sizing: border-box;
  padding-top: 17px;
  display: flex;
  justify-content: space-around;
}

.icon-box {
  height: 20vw;
  width: 20vw;
  border-radius: 11px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
}

.icon-box img {
  display: block;
  margin: auto;
  width: 35px;
  position: absolute;
  height: 35px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(-10px);
}

.icon-text {
  font-size: 12px;
  height: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.tab-bar {
  box-sizing: border-box;
  padding: 0 15px;
  padding-top: 17px;
  display: flex;
}

.tab-bar-item {
  width: 70px;
}

.tab-bar-item .top {
  color: rgb(200, 200, 200);
  height: 40px;
  line-height: 40px;
}

.tab-bar-item .bottom {
  height: 20px;
  width: 40px;
  margin: 0 auto;
}

.tab-bar .active .bottom {
  border-top: 1px solid rgb(23, 147, 225);
}

.tab-bar .active {
  width: 90px;
}

.tab-bar .active .top {
  color: black;
  font-size: 20px;
  line-height: 37px;
  font-weight: 500;
}

.questions {
  width: 100vw;
  padding: 0 15px;
  box-sizing: border-box;
}

.questions .row {
  width: 100%;
  height: 55px;
  line-height: 50px;
  border-bottom: 1px solid #00000036;
  display: flex;
}

.row .text {
  width: calc(100% - 20px);
  line-height: 65px;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}

.row .arrow {
  width: 20px;
  background: url(~@/assets/static/arrow.png) 0 0 / 100% 100% no-repeat;
  height: 30px;
  margin-top: 12px;
}

.chat-box {
  height: calc(100vh - 140px);
  width: 100vw;
  margin: 20px 0;
  box-sizing: border-box;
  padding: 0px 15px;

  overflow: auto;
}

.bot-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.bot-select-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.user-item {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 25px;
}

.bot-item>div,
.bot-select-item>div,
.user-item>div {
  padding: 8px;
  background: white;
  max-width: calc(100vw - 100px);
  line-height: 30px;
}

.bot-item>div {
  border-radius: 15px 15px 15px 0px;
}

.bot-select-item>div {
  border-radius: 15px 15px 15px 0px;
}

.user-item>div {
  border-radius: 15px 15px 0px 15px;
}

.bot-select-item>div>div {
  width: 100%;
}

.input-box {
  position: fixed;
  width: 100vw;
  bottom: 20px;
}
</style>
