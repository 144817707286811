function Record() {
  this.mediaRecorder = {}

  navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
    this.mediaRecorder = new MediaRecorder(stream)

  })


  this.stop = function () {
    return new Promise((resolve, reject) => {
      this.mediaRecorder.addEventListener('dataavailable', (e) => {
        let audioFile = new Blob([e.data], { type: "audio/wav" });
    
        resolve(audioFile)
      })
      this.mediaRecorder.stop()



    })
  }

}

export default Record



